import { Button, Form } from 'antd';
import classNames from 'classnames';

import { ContactUsModal } from '../contact-us-modal';

import { ContactUsOpenIcon } from './custom-icon';

import styles from './contact-us.module.css';

import { BUTTON_TEXT } from '~/constants/button';
import { CONTACT_FORM_INITIAL_VALUE, CONTACT_US } from '~/constants/contact-us';
import { useContactUs } from '~/hooks/use-contact-us';
import { CustomButton } from '~/shared/custom-button';
import { CustomModal } from '~/shared/custom-modal';
import { TextWithIcon } from '~/shared/text-with-icon';

export const ContactUs = () => {
    const {
        contactUsRef,
        toggleContactUsMenu,
        isOpenContactUs,
        openModal,
        setIsOpenMailModal,
        isOpenMailModal,
        isLoading,
        handleSendFeedback,
        isSubmitDisabled,
        form,
        frontConfig,
        modalType,
        isShowResultModal,
        closeResultMessageModal,
        setIsOpenResultModal,
        isOpenResultModal,
        resultMessage,
        isError,
    } = useContactUs();

    return (
        <nav ref={contactUsRef} className={styles.contactUsBlock}>
            <Button
                className={styles.contactUsButton}
                shape='circle'
                onClick={toggleContactUsMenu}
                icon={<ContactUsOpenIcon className={styles.contactUsButtonIcon} />}
            />
            <ul
                className={classNames(
                    styles.contactUsMenu,
                    isOpenContactUs && styles.contactUsMenuOpen,
                )}
            >
                {CONTACT_US.map(({ title, type, icon: Icon }) => (
                    <li key={title} className={styles.contactUsItem}>
                        <Button
                            icon={<Icon />}
                            iconPosition='end'
                            onClick={openModal(type)}
                            className={styles.contactUsReviewsButton}
                        >
                            {title}
                        </Button>
                    </li>
                ))}
            </ul>
            <CustomModal
                setOpenModal={setIsOpenMailModal}
                open={isOpenMailModal}
                isClose={true}
                footer={[
                    <CustomButton
                        key={BUTTON_TEXT.send}
                        className='enterPageButtonYes'
                        loading={isLoading}
                        onClick={handleSendFeedback}
                        disabled={isSubmitDisabled}
                    >
                        {BUTTON_TEXT.send}
                    </CustomButton>,
                ]}
            >
                <Form
                    form={form}
                    layout='vertical'
                    initialValues={CONTACT_FORM_INITIAL_VALUE}
                    disabled={isLoading}
                >
                    <ContactUsModal config={frontConfig?.rules} modalType={modalType} />
                </Form>
            </CustomModal>
            {isShowResultModal && (
                <CustomModal
                    setOpenModal={setIsOpenResultModal}
                    open={isOpenResultModal}
                    isClose={true}
                    className={styles.modalWidth}
                    footer={[
                        <CustomButton
                            key={BUTTON_TEXT.ok}
                            className='enterPageButtonYes'
                            onClick={closeResultMessageModal}
                        >
                            {BUTTON_TEXT.ok}
                        </CustomButton>,
                    ]}
                >
                    <TextWithIcon isError={isError} message={resultMessage} />
                </CustomModal>
            )}
        </nav>
    );
};
