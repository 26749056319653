import { FrontConfigLinksKey, FrontConfigType } from '~/utils/get-front-config/type';

type subTitleType = {
    text: string;
    linkText: string;
    link: string;
};

export const transformDataSubTitle = (subTitle: subTitleType, frontConfig?: FrontConfigType) => ({
    text: subTitle.text,
    linkText: subTitle.linkText,
    link: frontConfig ? frontConfig.links[FrontConfigLinksKey.UserDocs] : subTitle.link,
});
