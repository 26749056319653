import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';

import { LOCAL_STORAGE } from '~/constants/local-storage';
import { API } from '~/rtk-queries/constants/api';
import { LoginByEsiaResponse, ResponseToken, SessionDataResponse } from '~/rtk-queries/types/auth';
import { ApplicationState } from '~/store';
import {
    accessTokenSelector,
    clearStateOnLogout,
    setAccessToken,
    setAuthenticated,
    setSessionData,
} from '~/store/auth/auth-slice';
import { getLocalStorageItem } from '~/utils/local-storage';

const authQuery = fetchBaseQuery({});

const baseQuery = fetchBaseQuery({
    prepareHeaders: (headers, api) => {
        const { getState } = api;
        const state = getState() as ApplicationState;
        const accessToken = accessTokenSelector(state);

        if (accessToken) {
            headers.set('authorization', `Bearer ${accessToken}`);
        }

        return headers;
    },
});

let refreshTokenPromise: Promise<unknown> | null = null;

export const baseQueryWithReauth: BaseQueryFn<
    string | FetchArgs,
    unknown,
    FetchBaseQueryError
> = async (args, api, extraOptions: { auth?: boolean; ensureSession?: boolean }) => {
    const { dispatch } = api;
    const refreshToken = getLocalStorageItem(LOCAL_STORAGE.refreshToken);
    const sessionId = getLocalStorageItem(LOCAL_STORAGE.sessionId);

    const logOut = () => {
        localStorage.clear();
        dispatch(clearStateOnLogout());
    };

    const logIn = (response: ResponseToken) => {
        const { accessToken, refreshToken: newToken } = response;

        dispatch(setAccessToken(accessToken));
        dispatch(setAuthenticated(true));
        localStorage.setItem(LOCAL_STORAGE.isUserAuthenticated, String(true));
        localStorage.setItem(LOCAL_STORAGE.refreshToken, newToken);
    };

    // TODO логика авторизации
    if (extraOptions?.auth) {
        const authResult = await authQuery(args, api, extraOptions);

        if (extraOptions?.ensureSession) {
            const { data } = authResult;

            if (data) {
                localStorage.setItem(
                    LOCAL_STORAGE.sessionId,
                    String((data as SessionDataResponse).sessionId),
                );

                dispatch(setSessionData(data as SessionDataResponse));
            }
        }

        if (!extraOptions?.ensureSession) {
            if (authResult.data) {
                const responseData = authResult.data;

                const { response } = responseData as LoginByEsiaResponse;

                logIn(response);
            }

            if (authResult.error) {
                logOut();
            }
        }

        return authResult;
    }

    let result = await baseQuery(args, api, extraOptions);

    // TODO логика протухания токена
    if (result.error && result.error.status === 401 && refreshToken && sessionId) {
        if (!refreshTokenPromise) {
            refreshTokenPromise = (async () => {
                const refreshResult = await authQuery(
                    {
                        url: API.refreshToken,
                        method: 'POST',
                        body: {
                            sessionId: Number(sessionId),
                            refreshToken,
                        },
                    },
                    api,
                    extraOptions,
                );

                if (refreshResult.data) {
                    const { response } = refreshResult.data as LoginByEsiaResponse;

                    logIn(response);
                } else {
                    logOut();
                }

                refreshTokenPromise = null;

                return refreshResult.data;
            })();
        }

        const newTokens = await refreshTokenPromise;

        if (newTokens) {
            result = await baseQuery(args, api, extraOptions);
        }
    }

    return result;
};
