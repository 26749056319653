import React from 'react';
import { Form, Input, Typography } from 'antd';
import { Rule } from 'antd/es/form';
import TextArea from 'antd/es/input/TextArea';

import { CONTACT_FORM, CONTACT_US_MODAL_TITLE } from '~/constants/contact-us';
import { MessageType } from '~/rtk-queries/constants/feedback';
import { FrontConfigRulesKey } from '~/utils/get-front-config/type';
import { removeSpaces } from '~/utils/remove-spaces';
import { validateMessage } from '~/utils/validate-message';

type ContactUsModalType = {
    modalType: MessageType;
    config?: { [key in FrontConfigRulesKey]: string };
};

export const ContactUsModal = ({ modalType, config }: ContactUsModalType) => {
    const [email, textarea] = CONTACT_FORM;

    return (
        <React.Fragment>
            <Typography.Title level={4}>{CONTACT_US_MODAL_TITLE[modalType]}</Typography.Title>
            <Form.Item
                label={email.label}
                name={email.name}
                rules={email.rules as Rule[]}
                normalize={removeSpaces}
            >
                <Input placeholder={email.label} />
            </Form.Item>
            <Form.Item
                label={textarea.label}
                name={textarea.name}
                rules={[
                    ...(textarea.rules as Rule[]),
                    {
                        validator: (_: unknown, value: string) =>
                            validateMessage(
                                value,
                                Number(config?.minFeedbackMessage),
                                Number(config?.maxFeedbackMessage),
                            ),
                    },
                ]}
            >
                <TextArea autoSize={{ minRows: 4, maxRows: 4 }} placeholder={textarea.label} />
            </Form.Item>
        </React.Fragment>
    );
};
