import { useEffect, useRef, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Form } from 'antd';
import { Rule } from 'antd/es/form';
import { StoreValue } from 'antd/es/form/interface';

import styles from './custom-mask-input.module.css';

import { useCustomSelectProps } from '~/hooks/use-custom-props';
import { CustomTooltip } from '~/shared/custom-tooltip';
import { isNotEmptyArray } from '~/utils/is-not-empty-array';

type CustomRequestInputProps = Partial<{
    currentValue: string;
    label: string;
    name: string | string[];
    mask: string | null;
    disabled: boolean;
    maxLength: number;
    rules: Rule[];
    normalize?: (value: string) => StoreValue;
}>;

export const CustomMaskInput = ({
    currentValue,
    name,
    label,
    mask: _mask,
    disabled,
    maxLength,
    rules,
    normalize,
    ...restProps
}: CustomRequestInputProps) => {
    const refIMask = useRef(null);
    const inputRef = useRef(null);
    const [isRulesRequired, setIsRulesRequired] = useState(false);

    const { titleForTooltip, placeholder, backgroundColorDisabled } = useCustomSelectProps(
        currentValue,
        disabled || false,
        label,
    );

    const onFocusHandler = (event: {
        target: { setAttribute: (arg0: string, arg1: string) => void };
    }) => {
        event.target.setAttribute('autocomplete', 'off');
    };

    useEffect(() => {
        if (isNotEmptyArray(rules)) {
            if (Object.keys(rules[0]).includes('required')) {
                setIsRulesRequired(true);
            }
        }
    }, [rules]);

    return (
        <CustomTooltip title={titleForTooltip}>
            <Form.Item
                label={label}
                className={styles.inputItem}
                rules={rules}
                {...{ name, normalize }}
                {...restProps}
            >
                <IMaskInput
                    placeholder={placeholder}
                    style={backgroundColorDisabled}
                    onFocus={onFocusHandler}
                    maxLength={maxLength}
                    className={styles.inputMask}
                    disabled={disabled}
                    required={isRulesRequired}
                    // mask={mask?.includes('^') ? new RegExp(mask) : mask || ''}
                    mask=''
                    placeholderChar='#'
                    ref={refIMask}
                    inputRef={inputRef}
                    lazy={true}
                />
            </Form.Item>
        </CustomTooltip>
    );
};
