export const validateMessage = (value: string, minLength: number, maxLength: number) => {
    const trimmedValue = value?.trim();

    if (!value) {
        return Promise.resolve();
    }

    if (trimmedValue.length < minLength) {
        return Promise.reject(new Error(`Минимальная длина сообщения ${minLength} символа`));
    }

    if (trimmedValue.length > maxLength) {
        return Promise.reject(new Error(`Максимальная длина сообщения ${maxLength} символов`));
    }

    return Promise.resolve();
};
