import { FC, useMemo } from 'react';

import { InputValueType } from '~/constants/application-tab/certificate-form';
import { InfoAttribute, SelectDictionary } from '~/rtk-queries/types/user-info';
import { CustomDataPicker } from '~/shared/form-components/custom-data-picker';
import { CustomInput } from '~/shared/form-components/custom-input';
import { CustomMaskInput } from '~/shared/form-components/custom-mask-input';
import { CustomSelect } from '~/shared/form-components/custom-select';
import { CustomSelectOption } from '~/shared/form-components/custom-select/custom-select';
import { createValidationRules } from '~/utils/application-tab/create-validation-rules';

type PolymprphicInputProps = {
    attribute: InfoAttribute;
    dictionaries: SelectDictionary[];
};

export const PolymprphicInput: FC<PolymprphicInputProps> = ({
    attribute: { value, displayName: label, enabled, oid, regex, valueType, ...restAttrs },
    dictionaries,
}) => {
    const options = useMemo<CustomSelectOption[]>(() => {
        const [_, dictionaryKey] = valueType.split('.');

        if (dictionaryKey) {
            const matchedOptions = dictionaries.find(
                ({ selectName }) => selectName === dictionaryKey,
            );

            return matchedOptions?.values ?? [];
        }

        return [];
    }, [dictionaries, valueType]);

    const rules = createValidationRules({ ...restAttrs, regex, enabled });

    if (valueType.startsWith(InputValueType.Select)) {
        return (
            <CustomSelect key={oid} name={oid} disabled={!enabled} {...{ label, rules, options }} />
        );
    }

    switch (valueType) {
        case InputValueType.Date:
            return (
                <CustomDataPicker key={oid} name={oid} disabled={!enabled} {...{ label, rules }} />
            );

        case InputValueType.Integer:
        case InputValueType.String:
            return (
                <CustomMaskInput
                    currentValue={value}
                    key={oid}
                    name={oid}
                    mask={regex}
                    disabled={!enabled}
                    normalize={(rawValue: string) => rawValue.trim()}
                    {...{ label, rules }}
                />
            );

        default:
            return (
                <CustomInput
                    key={oid}
                    name={oid}
                    disabled={!enabled}
                    currentValue={value}
                    {...{ label, rules }}
                />
            );
    }
};
