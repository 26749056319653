import { ModalTextVariant } from '../../constants/modal';
import { getModalErrorText } from '../get-modal-error-text';

import { PluginErrorType } from '~/types/plugin';

// Утилита, которая устанавливает сертификат в контейнер, при нажатии в таблице на соответствующее действие
export const installCertificateUtil = async (data: string) => {
    const { enrolljs } = window;

    // вызываем метод из enrolljs для установки сертификата (происходит в фоновом режиме), принимает из таблицы сертификатов rawCertificate
    // второй параметр Контекст установки: true - машины, false -пользователя. По умолчанию - false(пользователя).
    // третий параметр Установить сертификат в локальное хранилище. По умолчанию - false (не устанавливать).
    try {
        await enrolljs.installCertificate(data, false, true);

        return { text: ModalTextVariant.installOk, isError: false, title: '' };
    } catch (e) {
        return getModalErrorText(e as PluginErrorType, true);
    }
};
