export const ESIA_REDIRECT = {
    tooltipText: 'Пожалуйста,\u00A0подождите. Личный кабинет сейчас откроется.',
};

export const EsiaCodeDescription: Record<string, string> = {
    'ESIA-007007':
        'Возникла неожиданная ошибка в работе сервиса авторизации, которая привела к невозможности выполнить запрос',
    'ESIA-007008':
        'Сервис авторизации в настоящее время не может выполнить запрос из-за большой нагрузки или технических работ на сервере',
    'ESIA-007019': 'Отсутствует разрешение на доступ',
    'ESIA-007036': 'Учетная запись заблокирована',
    'ESIA-007055': 'Вход в систему осуществляется с неподтвержденной учетной записью',
    'ESIA-007194':
        'Запрос области доступа для организации, сотрудником которой пользователь не является',
    'ESIA-007071':
        'Запрос персональных данных по физическим лицам может быть выполнен только с указанием согласий',
};
