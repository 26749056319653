import { API } from '../constants/api';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';
import { interceptor } from '../interceptor';

import { AlertMessage } from '~/constants/alert-message';
import { Tags } from '~/rtk-queries/constants/tags';
import {
    ApplyCertificateRequest,
    CertificatesListRequest,
    CertificatesListResponse,
    CreateCertificateBody,
    CreateDraftBody,
    DeleteDraftResponse,
    DraftResponse,
    PKIDetailsRequest,
    PKIDetailsResponse,
    RevokeCertificateRequest,
    SendToArchiveParams,
} from '~/rtk-queries/types/certificates';
import { CommonResponse } from '~/types/common';
import { transformCertificateData } from '~/utils/transform-certificates-data/transform-certificates-data';
import { GetCertificatesResponse } from '~/utils/transform-certificates-data/type';
import { transformTitleFilters } from '~/utils/transform-title-filters';

export const certificatesList = interceptor
    .enhanceEndpoints({ addTagTypes: [Tags.GET_CERTIFICATES_LIST] })
    .injectEndpoints({
        endpoints: (builder) => ({
            getCertificatesList: builder.query<
                CommonResponse<CertificatesListResponse>,
                Partial<CertificatesListRequest>
            >({
                query: (body) => ({
                    url: API.getCertificatesList,
                    method: 'POST',
                    body,
                }),
                transformResponse: ({
                    currentPage,
                    allTitles,
                    ...rest
                }: CommonResponse<GetCertificatesResponse>) => {
                    const { transformedCurrentPage, titleHashmap } =
                        transformCertificateData(currentPage);
                    const transformedFilters = transformTitleFilters(titleHashmap, allTitles);

                    return {
                        ...rest,
                        currentPage: transformedCurrentPage,
                        allTitles: transformedFilters,
                    };
                },
                providesTags: [Tags.GET_CERTIFICATES_LIST],
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),

            revokeCertificate: builder.mutation<null, RevokeCertificateRequest>({
                query: (body) => ({
                    url: `${API.revokeCertificateRequest}`,
                    method: 'POST',
                    body,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),

            createCertificateRequest: builder.mutation<CommonResponse, CreateCertificateBody>({
                query: ({ folderId, templateId, userInfoTemp }) => ({
                    url: `${API.createCertificateRequest}`,
                    method: 'POST',
                    body: {
                        folderId,
                        templateId,
                        userInfoTemp,
                    },
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),

            createDraftRequest: builder.mutation<CommonResponse, CreateDraftBody>({
                query: ({ requestId, folderId, templateId, userInfoTemp }) => ({
                    url: `${API.createDraftRequest}`,
                    method: 'POST',
                    body: {
                        requestId,
                        folderId,
                        templateId,
                        userInfoTemp,
                    },
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),

            applyRequest: builder.mutation<void, ApplyCertificateRequest>({
                query: (data) => ({
                    url: `${API.applyCertificateRequest}`,
                    method: 'POST',
                    body: data,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),

            sendToArchive: builder.mutation<void, SendToArchiveParams>({
                query: (params) => ({
                    url: `${API.sendToArchive}`,
                    method: 'POST',
                    params,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),

            unarchive: builder.mutation<void, SendToArchiveParams>({
                query: (params) => ({
                    url: `${API.unarchive}`,
                    method: 'POST',
                    params,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),

            getCertificatePKIDetails: builder.query<
                CommonResponse<PKIDetailsResponse>,
                PKIDetailsRequest
            >({
                query: (args) => ({
                    url: API.getCertificatePKIDetails,
                    method: 'GET',
                    params: args,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_REQUEST_DATA),
            }),

            getDraft: builder.query<CommonResponse<DraftResponse>, { RequestId: number }>({
                query: (args) => ({
                    url: API.getDraft,
                    method: 'GET',
                    params: args,
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
            }),

            removeDraft: builder.mutation<
                CommonResponse<DeleteDraftResponse>,
                { RequestId: number }
            >({
                query: (args) => ({
                    url: API.removeDraft,
                    method: 'DELETE',
                    params: args,
                    body: () => ({}),
                }),
                transformErrorResponse: (response) =>
                    transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
                invalidatesTags: (_, error) => (error ? [] : [Tags.GET_CERTIFICATES_LIST]),
            }),
        }),
    });

export const {
    useGetCertificatesListQuery,
    useLazyGetDraftQuery,
    useRevokeCertificateMutation,
    useCreateCertificateRequestMutation,
    useCreateDraftRequestMutation,
    useRemoveDraftMutation,
    useApplyRequestMutation,
    useLazyGetCertificatePKIDetailsQuery,
    useSendToArchiveMutation,
    useUnarchiveMutation,
} = certificatesList;
