import { CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { Flex, Typography } from 'antd';

import styles from './text-with-icon.module.css';

type TextWithIconType = {
    isError: boolean;
    message: string;
};

export const TextWithIcon = ({ isError, message }: TextWithIconType) => (
    <Flex className={styles.answerText}>
        {isError ? (
            <CloseCircleFilled className={styles.errorIcon} />
        ) : (
            <ExclamationCircleFilled className={styles.successIcon} />
        )}
        <Typography.Text className={styles.description}>{message}</Typography.Text>
    </Flex>
);
