import { useLayoutEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DeleteOutlined } from '@ant-design/icons';
import { FormInstance } from 'antd';

import { useApplicationModal } from '../use-application-modal';
import { useInitialForm } from '../use-initial-form';

import styles from './index.module.css';

import { AccountTab } from '~/constants/account-tab';
import { FormButtonLabel } from '~/constants/application-tab/general';
import { HttpStatus } from '~/constants/http-status';
import { ModalTextVariant } from '~/constants/modal';
import { ROUTES } from '~/constants/routes';
import { useGetUserInfoMutation } from '~/rtk-queries/endpoints/user-info';
import { CreateUserInfo } from '~/rtk-queries/types/certificates';
import { CertificateForm } from '~/types/application-tab/certificate-form';
import { resetCertificateForm } from '~/utils/application-tab/reset-certificate-form';
import {
    isErrorWithDataMessage,
    isFetchBaseQueryError,
} from '~/utils/type-guard/is-fetch-base-query-error';

export const useUserInfo = (
    form: FormInstance<CertificateForm>,
    _draft: CreateUserInfo | null,
    isFormShowed: boolean,
    deleteDraft: () => Promise<void>,
    hideForm: () => void,
) => {
    const navigate = useNavigate();
    const { openApplicationModal } = useApplicationModal();
    const [
        getUserInfo,
        {
            data: userInfoData,
            isLoading: isLoadingUserInfo,
            reset: resetUserInfoMutation,
            isSuccess,
            isError,
            error,
        },
    ] = useGetUserInfoMutation();

    const { initFormValues } = useInitialForm(userInfoData?.userInfo.blocks);

    useLayoutEffect(() => {
        if (isFormShowed) {
            form.setFieldsValue(initFormValues);
        }
    }, [isSuccess, form, initFormValues, isFormShowed]);

    useLayoutEffect(() => {
        const isServerError =
            isError &&
            isFetchBaseQueryError(error) &&
            error.status === HttpStatus.INTERNAL_SERVER_ERROR;

        if (isServerError && isErrorWithDataMessage(error)) {
            const isTemplateNotExist = error.data.message?.includes(
                "doesn't contain specified template",
            );

            if (isTemplateNotExist) {
                hideForm();
                resetUserInfoMutation();
                resetCertificateForm(form);

                navigate(`${ROUTES.privateRoom}/${AccountTab.applicationTab}`);

                openApplicationModal({
                    title: ModalTextVariant.attention,
                    content: ModalTextVariant.errorTemplateMessage,
                    icon: <DeleteOutlined className={styles.modalIcon} />,
                    okText: FormButtonLabel.removeDraft.toLocaleUpperCase(),
                    onOk: deleteDraft,
                });
            }
        }
    }, [
        navigate,
        hideForm,
        form,
        openApplicationModal,
        deleteDraft,
        resetUserInfoMutation,
        isError,
        error,
    ]);

    return { getUserInfo, userInfoData, isLoadingUserInfo };
};
