import { API } from '../constants/api';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';
import { interceptor } from '../interceptor';
import { FrontConfigResponse } from '../types/front-config';

import { AlertMessage } from '~/constants/alert-message';
import { getFrontConfig } from '~/utils/get-front-config/get-front-config';
import { FrontConfigType } from '~/utils/get-front-config/type';

export const frontConfig = interceptor.injectEndpoints({
    endpoints: (builder) => ({
        getFrontConfig: builder.query<FrontConfigType, void>({
            query: () => ({
                url: API.getFrontConfig,
                method: 'GET',
            }),
            transformResponse: (response: FrontConfigResponse) => getFrontConfig(response),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
    }),
});

export const { useGetFrontConfigQuery } = frontConfig;
