import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Flex, Form, Space, Spin, Tooltip, Typography } from 'antd';
import { useForm } from 'antd/es/form/Form';
import Link from 'antd/es/typography/Link';

import styles from './application-tab.module.css';

import { TemplatesSelect } from '~/components/application-tab';
import { ApplicationFormSection } from '~/components/application-tab/application-form-section/application-form-section';
import { TOOLTIP_TEXT_FOR_FORM } from '~/components/certificates-table/constants/general';
import { AccountTab } from '~/constants/account-tab';
import {
    DRAFT_MODE,
    NotificationTextPartOne,
    NotificationTextPartTwo,
} from '~/constants/application-tab/general';
import { AuthTypeLabel, DRAFT_FORM_TOOLTIP } from '~/constants/auth-draft';
import { AuthType } from '~/constants/auth-type';
import { BUTTON_TEXT } from '~/constants/button';
import { LOCAL_STORAGE } from '~/constants/local-storage';
import { ROUTES } from '~/constants/routes';
import { useAppDispatch } from '~/hooks';
import { useCreateApplication } from '~/hooks/application-tab/use-create-application';
import { useDraft } from '~/hooks/application-tab/use-draft';
import { useGetTemplateData } from '~/hooks/application-tab/use-get-template-data';
import { useUpdateDraft } from '~/hooks/application-tab/use-update-draft';
import { useUserInfo } from '~/hooks/application-tab/use-user-info';
import { CustomButton } from '~/shared/custom-button';
import { FormTooltip } from '~/shared/form-components/form-tooltip';
import { TextLinkEnd } from '~/shared/text-link-end';
import { setOpenModalForMoveFromApplicationTab } from '~/store/app-tab/app-tab-slice';
import { CertificateForm } from '~/types/application-tab/certificate-form';
import { resetCertificateForm } from '~/utils/application-tab/reset-certificate-form';
import { getLocalStorageItem } from '~/utils/local-storage';

export const ApplicationTab = () => {
    const storedDraftData = getLocalStorageItem(LOCAL_STORAGE.draftData);

    const navigate = useNavigate();
    const location = useLocation();
    const isDraftMode = Boolean(location.state?.[DRAFT_MODE]);

    const dispatch = useAppDispatch();
    const [form] = useForm<CertificateForm>();
    const [isFormShowed, setIsFormShowed] = useState(false);

    const hideForm = () => setIsFormShowed(false);

    const { draft, isDraftInteractionInProgress, deleteDraft } = useDraft();

    const {
        templateSetup: { folderId, templateId },
        selectedTemplate,
        changeTemplate,
        resetTemplate,
        selectedTemplateName,
        isFetchingTemplate,
    } = useGetTemplateData();

    const { getUserInfo, userInfoData, isLoadingUserInfo } = useUserInfo(
        form,
        draft,
        isFormShowed,
        deleteDraft,
        hideForm,
    );

    const { updateDraft, isLoadingDraftCertificate } = useUpdateDraft(
        form,
        // { folderId, templateId },
        // userInfoData,
        // selectedTemplateName,
    );

    const { createApplication, isLoadingCreateCertificate } = useCreateApplication(
        form,
        // { folderId, templateId },
        // userInfoData,
        // deleteDraft,
        // selectedTemplateName,
    );

    const isLoading =
        isFetchingTemplate ||
        isLoadingUserInfo ||
        isLoadingCreateCertificate ||
        isLoadingDraftCertificate ||
        isDraftInteractionInProgress;

    const formDisabled = isLoadingCreateCertificate || isLoadingDraftCertificate;

    const draftButtonTooltip = useMemo(() => {
        type LabelKey = Exclude<AuthType, 0>;

        const labelKey = Number(localStorage.getItem(LOCAL_STORAGE.authType)) as LabelKey;

        return `${DRAFT_FORM_TOOLTIP} ${AuthTypeLabel[labelKey]}`;
    }, []);

    const navigateForm = useCallback(() => {
        if (folderId && templateId) {
            getUserInfo({ folderId, templateId });
            dispatch(setOpenModalForMoveFromApplicationTab(true));

            setIsFormShowed(true);
        }
    }, [dispatch, folderId, getUserInfo, templateId]);

    const navigateBack = () => {
        resetCertificateForm(form);
        setIsFormShowed(false);
        resetTemplate();
        navigate(`${ROUTES.privateRoom}/${AccountTab.applicationTab}`);
    };

    useLayoutEffect(() => {
        if (storedDraftData) {
            navigateForm();
        }
    }, [navigateForm, storedDraftData]);

    if (isDraftMode || isFormShowed) {
        return (
            <Spin spinning={isLoading} className={styles.spinner}>
                {!isLoadingUserInfo && (
                    <Form form={form} className={styles.applicationTabForm} layout='vertical'>
                        <div className={styles.applicationTabBlocks}>
                            <div className={styles.titleBlock}>
                                <FormTooltip title={TOOLTIP_TEXT_FOR_FORM} placement='topRight'>
                                    <Button
                                        type='text'
                                        className={styles.backButton}
                                        onClick={navigateBack}
                                    >
                                        <ArrowLeftOutlined className={styles.topArrowIcon} />
                                    </Button>
                                </FormTooltip>

                                <Typography.Title className={styles.applicationTitle} level={3}>
                                    {selectedTemplateName}
                                </Typography.Title>
                            </div>

                            <Space className={styles.changeDataNotification}>
                                <TextLinkEnd screenTitle={NotificationTextPartOne} />
                                <span className={styles.notificationText}>
                                    {NotificationTextPartTwo}
                                </span>
                            </Space>

                            {userInfoData &&
                                userInfoData.userInfo.blocks.map((block) => (
                                    <ApplicationFormSection
                                        {...{ block, dictionaries: userInfoData.selectData }}
                                        key={block.displayName}
                                    />
                                ))}

                            <Flex
                                wrap={true}
                                vertical={true}
                                align='flex-start'
                                className={styles.buttonsRow}
                                gap={32}
                            >
                                <FormTooltip title={TOOLTIP_TEXT_FOR_FORM} placement='topLeft'>
                                    <Link onClick={navigateBack}>
                                        <ArrowLeftOutlined className={styles.arrowIcon} />
                                        {BUTTON_TEXT.back}
                                    </Link>
                                </FormTooltip>
                                <Space size={32}>
                                    <Tooltip
                                        title={draftButtonTooltip}
                                        placement='top'
                                        overlayStyle={{ maxWidth: 'var(--button-width-desktop)' }}
                                    >
                                        <div>
                                            <CustomButton
                                                disabled={formDisabled}
                                                className='updatePageButton'
                                                onClick={updateDraft}
                                            >
                                                {BUTTON_TEXT.saveDraft}
                                            </CustomButton>
                                        </div>
                                    </Tooltip>

                                    <CustomButton
                                        disabled={formDisabled}
                                        className='applicationTabButton'
                                        onClick={createApplication}
                                        type='primary'
                                    >
                                        {BUTTON_TEXT.sendRequest}
                                    </CustomButton>
                                </Space>
                            </Flex>
                        </div>
                    </Form>
                )}
            </Spin>
        );
    }

    return (
        <TemplatesSelect
            isLoading={isFetchingTemplate}
            onClickContinue={navigateForm}
            {...{ selectedTemplate, changeTemplate }}
        />
    );
};
