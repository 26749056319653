import { API } from '../constants/api';
import { SendFeedbackType } from '../constants/feedback';
import { transformErrorWithAlert } from '../helpers/transform-error-with-alert';
import { interceptor } from '../interceptor';

import { AlertMessage } from '~/constants/alert-message';

export const feedback = interceptor.injectEndpoints({
    endpoints: (builder) => ({
        sendFeedback: builder.mutation<void, SendFeedbackType>({
            query: (data) => ({
                url: API.sendFeedback,
                method: 'POST',
                body: data,
            }),
            transformErrorResponse: (response) =>
                transformErrorWithAlert(response, AlertMessage.ERROR_LOADING),
        }),
    }),
});

export const { useSendFeedbackMutation } = feedback;
