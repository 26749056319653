export enum MessageType {
    feedback = 0,
    mail = 1,
}

export type SendFeedbackType = {
    senderEmail: string;
    message: string;
    messageType: MessageType;
};
