import { ConfigItemType } from '~/rtk-queries/types/front-config';

export const getConfigObject = (response: ConfigItemType[]) =>
    response.reduce(
        (result: Record<string, string>, item: { key: string; value: string }) => ({
            ...result,
            [item.key]: item.value,
        }),
        {},
    );
