import { FC } from 'react';

import { PolymprphicInput } from '../polymorphic-input/polymorphic-input';

import styles from './application-form-section.module.css';

import { InfoBlock, SelectDictionary } from '~/rtk-queries/types/user-info';

type ApplicationFormSectionProps = {
    block: InfoBlock;
    dictionaries: SelectDictionary[];
};

export const ApplicationFormSection: FC<ApplicationFormSectionProps> = ({
    block: { displayName, attributes },
    dictionaries,
}) => (
    <div className={styles.container}>
        <div className={styles.applicationTabSubtitle}>{displayName}</div>
        <div className={styles.applicationTabBlock}>
            {attributes.map((attribute) => (
                <PolymprphicInput {...{ attribute, dictionaries }} key={attribute.oid} />
            ))}
        </div>
    </div>
);
