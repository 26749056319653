import { getConfigObject } from './get-config-object';
import { FrontConfigType } from './type';

import { FrontConfigResponse } from '~/rtk-queries/types/front-config';

export const getFrontConfig = ({ links, rules }: FrontConfigResponse) =>
    ({
        links: getConfigObject(links),
        rules: getConfigObject(rules),
    }) as FrontConfigType;
