import { useParams } from 'react-router-dom';
import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';

import styles from './check-and-sign-certificate.module.css';

import { CONTENT } from '~/components/check-and-sign-certificate/content/constants/content';
import { Sidebar } from '~/components/check-and-sign-certificate/sidebar';
import { KEYS_FOR_TAB } from '~/components/check-and-sign-certificate/types/keys-for-tab';
import { WithLoader } from '~/hoc/with-loader';
import { useGetSvsPolicyQuery } from '~/rtk-queries/endpoints/certificate-verification';
import { useGetFrontConfigQuery } from '~/rtk-queries/endpoints/front-config';

export const CheckAndSignCertificate = () => {
    const { tabCheckCertificate } = useParams();
    const contentTab = (tabCheckCertificate as KEYS_FOR_TAB) ?? '';
    const { isLoading } = useGetSvsPolicyQuery();
    const { isLoading: isLoadingFrontConfig } = useGetFrontConfigQuery();

    return (
        <WithLoader isLoading={isLoading || isLoadingFrontConfig} className={styles.spinner}>
            <Layout className={styles.wrapper}>
                <Sidebar />
                <Layout className={styles.layout}>
                    <Content className={styles.content}>{CONTENT[contentTab]}</Content>
                </Layout>
            </Layout>
        </WithLoader>
    );
};
