import React from 'react';
import { Divider, Space, Typography } from 'antd';
import { FormInstance } from 'antd/es/form';

import { SignDocumentFields, SignedFormTypes } from '../../types/form-types';

import styles from './sign-form.module.css';

import { BUTTON_TEXT } from '~/constants/button';
import {
    MAX_SIZE_DOCUMENT_FOR_SIGN,
    SIGNATURE_TYPE_OPTIONS,
    SIGNATURE_TYPE_VARIANT,
    SignatureTooltip,
    SignDocumentText,
} from '~/constants/sign-document';
import { CustomButton } from '~/shared/custom-button';
import { CustomRow } from '~/shared/custom-row';
import { FormUpload } from '~/shared/files-upload';
import { CustomForm } from '~/shared/form-components/custom-form';
import { CustomSelect } from '~/shared/form-components/custom-select';
import { ConfigRules } from '~/utils/get-front-config/type';

type SignFormProps = {
    userCertificates: { value: string; label: string }[];
    form: FormInstance<SignedFormTypes>;
    initialCertificate: string;
    isDisabledSignButton: boolean;
    onSignDocument: () => void;
    isDisabledSelect: boolean;
    rules?: ConfigRules;
};

export const SignForm = ({
    userCertificates,
    form,
    onSignDocument,
    isDisabledSignButton,
    initialCertificate,
    isDisabledSelect,
    rules,
}: SignFormProps) => (
    <CustomForm layout='vertical' form={form} disabled={false}>
        <CustomRow columnSpan={24} rowGutter={[0, 12]}>
            <React.Fragment>
                <FormUpload
                    maxFileSizeMb={Number(rules?.SigFile) || MAX_SIZE_DOCUMENT_FOR_SIGN}
                    name={SignDocumentFields.content}
                    title={<h4>{SignDocumentText.uploadFile}</h4>}
                    fileListWidth={100}
                    isRequired={true}
                    fileListBlock={true}
                    maxCount={1}
                    hideUploadButtonAfterLastFileUploaded={true}
                    buttonText={SignDocumentText.buttonText}
                    showExtensionIcon={true}
                />
                <Divider className={styles.divider} />
            </React.Fragment>
            <Typography.Text>{SignDocumentText.certificate}</Typography.Text>
            <CustomSelect
                disabled={isDisabledSelect}
                itemName={SignDocumentFields.thumbprint}
                initialValue={initialCertificate}
                options={userCertificates}
                tooltipInfoTitle={SignatureTooltip.certificate}
            />
            <Space>
                <CustomSelect
                    disabled={isDisabledSelect}
                    itemName={SignDocumentFields.signatureType}
                    initialValue={SIGNATURE_TYPE_VARIANT.detached.value}
                    options={SIGNATURE_TYPE_OPTIONS}
                    tooltipInfoTitle={SignatureTooltip.signature}
                />
                <CustomButton
                    disabled={isDisabledSignButton}
                    className='signButton'
                    onClick={onSignDocument}
                >
                    {BUTTON_TEXT.signFile}
                </CustomButton>
            </Space>
        </CustomRow>
    </CustomForm>
);
