import { InfoCircleOutlined } from '@ant-design/icons';
import { Form, Select, Tooltip } from 'antd';
import { Rule } from 'antd/es/form';

import styles from './custom-select.module.css';

import { useCustomSelectProps } from '~/hooks/use-custom-props';
import { CustomSpace } from '~/shared/custom-space';
import { CustomTooltip } from '~/shared/custom-tooltip';

export type CustomSelectOption = {
    value: string | number;
    label: string;
};

type CustomSelectProps = Partial<{
    options: CustomSelectOption[];
    initialValue: string | number;
    tooltipInfoTitle: string;
    currentValue: string | number;
    label: string;
    name: string | string[];
    itemName: string;
    rules: Rule[];
    disabled: boolean;
}>;

export const CustomSelect = ({
    initialValue,
    options,
    tooltipInfoTitle,
    currentValue,
    label,
    name,
    itemName,
    rules,
    disabled,
    ...restProps
}: CustomSelectProps) => {
    const filterOption = (input: string, option?: { label: string; value: string | number }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    const { titleForTooltip: title, placeholder } = useCustomSelectProps(
        currentValue,
        disabled || false,
        label,
        itemName,
    );

    return tooltipInfoTitle ? (
        <CustomSpace direction='horizontal' size={5} className={styles.customLabel}>
            <Form.Item
                className={styles.selectItem}
                name={itemName || name}
                {...restProps}
                {...{ label, rules, initialValue }}
            >
                <Select
                    className={styles.select}
                    popupMatchSelectWidth={false}
                    showSearch={true}
                    {...{ options, placeholder, disabled, filterOption }}
                />
            </Form.Item>

            <Tooltip title={tooltipInfoTitle}>
                <InfoCircleOutlined className={styles.icon} />
            </Tooltip>
        </CustomSpace>
    ) : (
        <CustomTooltip {...{ title }}>
            <Form.Item
                className={styles.selectItem}
                {...restProps}
                {...{ name, label, rules, initialValue }}
            >
                <Select
                    className={styles.select}
                    popupMatchSelectWidth={false}
                    showSearch={true}
                    {...{ options, filterOption, placeholder, disabled }}
                />
            </Form.Item>
        </CustomTooltip>
    );
};
