import { useEffect, useRef, useState } from 'react';
import { Form } from 'antd';

import { useClickOutside } from './use-click-outside';

import { CONTACT_US_MODAL_TITLE } from '~/constants/contact-us';
import { MessageType } from '~/rtk-queries/constants/feedback';
import { useSendFeedbackMutation } from '~/rtk-queries/endpoints/feedback';
import { useGetFrontConfigQuery } from '~/rtk-queries/endpoints/front-config';

type FormValues = {
    email: string;
    message: string;
};

export const useContactUs = () => {
    const [isOpenContactUs, setIsOpenContactUs] = useState(false);
    const [isOpenMailModal, setIsOpenMailModal] = useState(false);
    const [isOpenResultModal, setIsOpenResultModal] = useState(false);
    const [modalType, setModalType] = useState<MessageType>(MessageType.feedback);
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const contactUsRef = useRef<HTMLInputElement | null>(null);
    const { data: frontConfig } = useGetFrontConfigQuery();
    const [form] = Form.useForm<FormValues>();
    const values = Form.useWatch([], form);
    const [sendFeedback, { isLoading, isError, isSuccess }] = useSendFeedbackMutation();

    const isShowResultModal = isError || isSuccess;
    const resultMessage = isError ? CONTACT_US_MODAL_TITLE.error : CONTACT_US_MODAL_TITLE.success;

    useClickOutside(contactUsRef, () => {
        if (!isOpenMailModal) {
            setIsOpenContactUs(false);
        }
    });

    const toggleContactUsMenu = () => {
        setIsOpenContactUs(!isOpenContactUs);
    };

    const openModal = (type: MessageType) => () => {
        setModalType(type);
        setIsOpenMailModal(true);
    };

    const closeModal = () => {
        setIsOpenMailModal(false);
        form.resetFields();
    };

    const closeResultMessageModal = () => {
        setIsOpenResultModal(false);
    };

    const handleSendFeedback = async () => {
        try {
            const { email, message } = await form.validateFields();
            const payload = {
                senderEmail: email,
                message: message.trim(),
                messageType: modalType,
            };

            await sendFeedback(payload).unwrap();
            closeModal();
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        if (!isOpenMailModal) {
            form.resetFields();
        }
    }, [form, isOpenMailModal]);

    useEffect(() => {
        if (values) {
            form.validateFields({ validateOnly: true })
                .then(() => setIsSubmitDisabled(false))
                .catch(() => setIsSubmitDisabled(true));
        }
    }, [form, values]);

    useEffect(() => {
        if (isError || isSuccess) {
            setIsOpenResultModal(true);
        }
    }, [isError, isSuccess]);

    return {
        contactUsRef,
        toggleContactUsMenu,
        isOpenContactUs,
        openModal,
        setIsOpenMailModal,
        isOpenMailModal,
        isLoading,
        handleSendFeedback,
        isSubmitDisabled,
        form,
        frontConfig,
        modalType,
        isShowResultModal,
        closeResultMessageModal,
        setIsOpenResultModal,
        isOpenResultModal,
        resultMessage,
        isError,
    };
};
