import { useMemo } from 'react';
import dayjs from 'dayjs';

import { InputValueType } from '~/constants/application-tab/certificate-form';
import { DATE_FORMAT } from '~/constants/general';
import { InfoAttribute, InfoBlock } from '~/rtk-queries/types/user-info';
import { CertificateForm } from '~/types/application-tab/certificate-form';

export const useInitialForm = (
    infoblocks: InfoBlock[] | undefined,
    // draft: CreateUserInfo | null,
) => {
    const initFormValues: CertificateForm = useMemo(() => {
        const flatAttributes: InfoAttribute[] = [];

        infoblocks?.forEach(({ attributes }) => {
            flatAttributes.push(...attributes);
        });

        const initialForm: CertificateForm = {};

        flatAttributes.forEach(({ oid, value, valueType }) => {
            if (valueType === InputValueType.Date) {
                initialForm[oid] = value ? dayjs(value, DATE_FORMAT) : null;
            } else {
                initialForm[oid] = value;
            }
        });

        return initialForm;
    }, [infoblocks]);

    return { initFormValues };
};
